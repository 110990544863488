<template>
	<div class="login-container flex">
		<img src="@/assets/img/logo-head3.png" class="logo" />
		<div class="form-box" v-if="!successLogin">
			<div class="main-content">
				<div class="loin-form">
					<el-form ref="dataPO" :model="dataPO" :rules="rules">
						<el-form-item prop="phone">
							<el-input v-model="dataPO.phone" oninput="value=value.replace(/[^\d]/g,'')"
								@blur="dataPO.phone = $event.target.value" placeholder="您的手机号"
								prefix-icon="iconfont icon-shouji"></el-input>
						</el-form-item>
						<el-form-item prop="code">
							<div class="code-form-box">
								<el-input class="code-form" v-model="dataPO.code" placeholder="请输入验证码"
									prefix-icon="iconfont icon-suo"></el-input>
								<div class="get-code" @click.prevent="getCode()">
									{{ codeText }}
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="agree-box">
					<el-checkbox v-model="agreeChecked"></el-checkbox>
					<span class="content">我已阅读并同意</span>
					<span class="agree" @click="openAgree">《注册协议》</span>
					<span style="font-size: 0.73vw;">、</span>
					<span class="agree" @click="openPrivacy">《隐私政策》</span>
				</div>
				<div class="submit-form" @click.prevent="submitForm">
					立即注册
				</div>
			</div>
		</div>
		<div class="footer-box">
			<div class="footer">
				<div class="msg-box">
					<span>皖ICP备19004658号 | 皖公网安备 11010502030143</span>
				</div>
			</div>
		</div>
		<agreement-modal ref="agreementModal" :title="'用户注册协议'" :text="false"></agreement-modal>
		<privacy-modal ref="privacyModal"></privacy-modal>
		<Vcode :show="isShow" @success="success" @close="close" />
		<div class="toast text-center" v-if="successLogin">恭喜您，注册成功！<br>{{time}}秒后跳转下载页面</div>
		<div class="toast2 text-center" v-if="toast">{{text}}</div>
	</div>
</template>

<script>
import AgreementModal from "@/components/agreementModal";
import privacyModal from "@/components/privacyModal";
import { doEncrypt } from "@/utils/smCrypto.js";
import Vcode from "vue-puzzle-vcode";
export default {
	components: {
		AgreementModal,
		privacyModal,
		Vcode
	},
	data() {
		let checkPhone = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("请输入手机号"));
			} else {
				const reg = "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
				if (new RegExp(reg).test(value)) {
					callback();
				} else {
					return callback(new Error("请输入正确的手机号"));
				}
			}
		};
		return {
			status: "",
			key: "",
			qrCode: "",
			activeName: "phone",
			dataPO: {
				phone: "", //手机号
				code: "", // 验证码
				terminal: 1, // pc端
			},
			rules: {
				phone: [{ validator: checkPhone, trigger: "blur" }],
				code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
			},
			codeText: "获取验证码",
			agreeChecked: false, //同意协议状态
			mask: true,
			isShow: false,
			successLogin:false,
			toast:false,
			text:'',
			time:3,
			timerId:null,
			timerId2:null,
		};
	},
	mounted() {
		console.log(this.$VERSION, "this.$VERSION");
		console.log(this.$route.query);
	},
	beforeDestroy() {
		clearInterval(this.timerId);
		clearInterval(this.timerId2);
	},
	watch: {
	},
	methods: {
		// 进入首页
		gotoHome() {
			this.$router.push("/home");
		},
		// 切换注册方式
		handleClick(tab, event) {
			console.log(tab, event);
			if (this.status != "") {
				clearInterval(this.status);
				this.status = "";
			}
			if (tab.index == 1) {
				this.key = new Date().getTime();
				this.qrCode = process.env.VUE_APP_BASE_URL + "/auth/api/refreshQrCode?key=" + this.key + "&deviceCode=" + this.$VERSION;
				let that = this;
				this.status = setInterval(function () {
					that.checkQrLoginStatus();
				}, 2000);
				this.$once("hook:beforeDestroy", () => {
					clearInterval(this.status);
					this.status = "";
				});
			}
		},
		success() {
			let smPhone = doEncrypt(this.dataPO.phone)
			this.$api.user
				.getVerificationCode({ phone: smPhone })
				.then(() => {
					this.text='已发送，请注意查收'
					this.toast=true
					this.timerId2=setTimeout(() => {
						this.toast=false
					}, 1500);
					let maxNum = 59;
					let oldCodeText = this.codeText;
					this.codeText = `${maxNum + 1}s重新发送`; //初始显示倒计时
					let codeCountDown = setInterval(() => {
						let countDownNum = maxNum--;
						this.codeText = `${countDownNum}s重新发送`;
						if (countDownNum == 0) {
							//倒计时结束
							this.codeText = oldCodeText;
							clearInterval(codeCountDown);
						}
					}, 1000);
				})
				.catch(err => {
					if(err&&err.msg){
						this.text=err.msg
						this.toast=true
						this.timerId2=setTimeout(() => {
							this.toast=false
						}, 1500);
					}
				}).finally(() => {
					this.close()
				});
		},
		close() {
			this.isShow = false;
		},
		// 获取验证码
		getCode() {
			console.log(1)
			if (this.codeText !== "获取验证码") {
				return;
			}
			this.$refs.dataPO.validateField("phone", errorMessage => {
				if (!errorMessage) {
					if (!this.agreeChecked) {
						this.text='请勾选用户协议'
						this.toast=true
						this.timerId2=setTimeout(() => {
							this.toast=false
						}, 1500);
						return;
					}
					this.isShow = true;
				}
			});
		},
		// 点击协议
		openAgree() {
			this.$refs.agreementModal.visible = true;
			// this.$refs.agreementModal.init();
		},
		// 打开免责声明
		openPrivacy() {
			this.$refs.privacyModal.visible = true;
		},
		// 点击注册
		submitForm() {
			this.$refs.dataPO.validate(valid => {
				if (valid) {
					if (!this.agreeChecked) {
						this.text='请勾选用户协议'
						this.toast=true
						this.timerId2=setTimeout(() => {
							this.toast=false
						}, 1500);
						return;
					}
					const loading = this.$loading({
						lock: true,
						text: "注册中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					let params = { ...this.dataPO,...this.$route.query };
					params.deviceCode = this.$VERSION;
					this.$api.user
						.doLogin(params)
						.then(res => {
							if(res.code==200){
								this.successLogin=true
								setTimeout(()=>{
									window.location.href='https://mds.ctcemti.com/downloadapp.html'
								},this.time*1000)
								this.setTimeNumber()
							}else{
								this.text=res.msg
								this.toast=true
								this.timerId2=setTimeout(() => {
									this.toast=false
								}, 1500);
							}
						})
						.catch(err => {
							if(err&&err.msg){
								this.text=err.msg
								this.toast=true
								this.timerId2=setTimeout(() => {
									this.toast=false
								}, 1500);
							}
						})
						.finally(() => {
							loading.close();
						});
				} else {
					return false;
				}
			});
		},
		setTimeNumber(){
			this.timerId=setTimeout(() => {
				this.time--;
				this.setTimeNumber()
			}, 1000);
		},
		 
	},
};
</script>

<style lang="less" scoped>
@import "./mobile.less";
.toast{
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 45%;
	width: 60%;
	z-index: 2;
	padding:20px;
	border-radius: 5px;
	color: #fff;
}
.toast2{
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 45%;
	width: 50%;
	z-index: 2;
	padding:10px;
	border-radius: 5px;
	color: #fff;
}
</style>
